<template>
  <div v-if="value"
    class="tile is-parent pl-0">
    <article class="tile is-child box">
      <div class="columns mb-0">
        <div class="column is-flex is-align-items-center">
          <i class="icon mdi mdi-account-outline mdi-24px" />
          <p class="title pl-1">Customer</p>
        </div>
        <div class="column is-flex is-justify-content-flex-end">
          <div>
            <div class="pretty p-default p-round p-smooth is-size-7">
              <input id="chk-pretty-customer-type"
                v-model="innerCustomer.isPerson"
                name="chk-pretty-customer-type"
                :value="false"
                type="radio">
              <div class="state p-primary">
                <label class="is-size-7">Company</label>
              </div>
            </div>
            <div class="pretty p-default p-round p-smooth is-size-7">
              <input id="chk-pretty-customer-type"
                v-model="innerCustomer.isPerson"
                name="chk-pretty-customer-type"
                :value="true"
                type="radio">
              <div class="state p-primary">
                <label class="is-size-7">Person</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="is-divider" />
      <div class="field">
        <div v-if="!innerCustomer.isPerson"
          class="field">
          <label class="label is-required-label">Company Name</label>
          <div class="control">
            <bulma-autocomplete v-model="innerCustomer.companyName"
              :data="filteredCustomers"
              field="companyName"
              @input="searchCustomer('companyName')"
              @select="selectCustomer"
              :loading="isSearchingCustomer"
              :expanded="true"
              :open-on-focus="innerCustomer.isNew"
              :class-prop="!$v.customer.companyName.$error ? 'is-required-field' : 'is-invalid'"
              placeholder="Company Name">
              <template slot-scope="props">
                <div>{{ props.option.value }}</div>
                <div class="help is-primary">
                  <span class="pr-1">
                    <i class="mdi mdi-phone mdi-12px" />
                  </span>
                  <span>{{ props.option.phoneNo }}</span>
                </div>
              </template>
            </bulma-autocomplete>
          </div>
        </div>
        <div v-else
          class="field-body">
          <div class="field">
            <label class="label is-required-label">First Name</label>
            <div class="control">
              <bulma-autocomplete v-model="innerCustomer.firstname"
                :data="filteredCustomers"
                field="firstname"
                @input="searchCustomer('firstname')"
                @select="selectCustomer"
                :loading="isSearchingCustomer"
                :expanded="true"
                :open-on-focus="innerCustomer.isNew"
                :class-prop="!$v.customer.firstname.$error ? 'is-required-field' : 'is-invalid'"
                placeholder="First Name">
                <template slot-scope="props">
                  <div>{{ props.option.value }}</div>
                  <div class="help is-primary">
                    <span class="pr-1">
                      <i class="mdi mdi-phone mdi-12px" />
                    </span>
                    <span>{{ props.option.phoneNo }}</span>
                  </div>
                </template>
              </bulma-autocomplete>
            </div>
          </div>
          <div class="field">
            <label class="label is-required-label">Last Name</label>
            <div class="control">
              <bulma-autocomplete v-model="innerCustomer.lastname"
                :data="filteredCustomers"
                field="lastname"
                @input="searchCustomer('lastname')"
                @select="selectCustomer"
                :loading="isSearchingCustomer"
                :expanded="true"
                :open-on-focus="innerCustomer.isNew"
                :class-prop="!$v.customer.lastname.$error ? 'is-required-field' : 'is-invalid'"
                placeholder="Last Name">
                <template slot-scope="props">
                  <div>{{ props.option.value }}</div>
                  <div class="help is-primary">
                    <span class="pr-1">
                      <i class="mdi mdi-phone mdi-12px" />
                    </span>
                    <span>{{ props.option.phoneNo }}</span>
                  </div>
                </template>
              </bulma-autocomplete>
            </div>
          </div>
        </div>
      </div>
      <transition name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="animate__animated animate__fadeOut animate__faster">
        <div v-show="$showMoreCustomer">
          <div class="field">
            <div class="is-flex is-justify-content-space-between">
              <label class="label">Emails</label>
              <button v-show="activeEmails.length < 2"
                class="button is-small is-primary is-light tooltip"
                @click="addEmail"
                data-tooltip="Add Email">
                <i class="icon mdi mdi-plus mdi-24px" />
              </button>
            </div>
            <template>
              <div class="field"
                v-for="(email,index) in activeEmails"
                :key="index">
                <div class="field has-addons">
                  <div class="control">
                    <span class="select custom-select">
                      <select v-model="email.type">
                        <option v-for="(emailType,p) in emailTypes"
                          :value="emailType"
                          :key="p"
                          :title="p">
                          {{ Array.from(p)[0] }}
                        </option>
                      </select>
                    </span>
                  </div>
                  <div class="control is-expanded has-icons-right">
                    <input class="input"
                      :class="[ !$v.customer.emails.$each[index].address.email ? 'is-invalid' : '']"
                      type="email"
                      placeholder="Email"
                      v-model="email.address"
                      v-focus-inserted="{ focus: email.isNew }">
                    <span class="icon is-right cursor-pointer"
                      :class="[ email.isDefault ? 'has-text-gold' : 'has-text-grey-light' ]"
                      @click="setDefaultEmail(index)">
                      <i class="mdi mdi-star mdi-24px" />
                    </span>
                  </div>
                  <div class="control">
                    <button class="button has-text-danger"
                      @click="deleteEmail(email, index)">
                      <i class="icon mdi mdi-minus mdi-24px" />
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="field">
            <div class="is-flex is-justify-content-space-between">
              <label class="label">Phones</label>
              <button v-show="activePhones.length < 3"
                class="button is-small is-primary is-light"
                @click="addPhone">
                <i class="icon mdi mdi-plus mdi-24px" />
              </button>
            </div>
            <template>
              <div class="field"
                v-for="(phone,index) in activePhones"
                :key="index">
                <div class="field has-addons">
                  <div class="control">
                    <span class="select custom-select">
                      <select v-model="phone.type">
                        <option v-for="(phoneType,p) in phoneTypes"
                          :value="phoneType"
                          :key="p"
                          :title="p">
                          {{ Array.from(p)[0] }}
                        </option>
                      </select>
                    </span>
                  </div>
                  <div class="control is-expanded has-icons-right">
                    <the-mask v-model="phone.no"
                      :mask="phoneNoMask"
                      :tokens="phoneNoTokens"
                      class="input"
                      placeholder="Phone"
                      v-focus-inserted="{ focus: phone.isNew}" />
                    <span class="icon is-right cursor-pointer"
                      :class="[ phone.isDefault ? 'has-text-gold' : 'has-text-grey-light' ]"
                      @click="setDefaultPhone(index)">
                      <i class="mdi mdi-star mdi-24px" />
                    </span>
                  </div>
                  <div class="control">
                    <button class="button has-text-danger"
                      @click="deletePhone(phone, index)">
                      <i class="icon mdi mdi-minus mdi-24px" />
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <customer-address-input ref="addressInput"
            v-model="defaultAddress" />
        </div>
      </transition>
    </article>
  </div>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'
import { EmailTypes, PhoneTypes, EventHubTypes } from '@/enums'
import CustomerAddressInput from './CustomerAddressInput.vue'
import { FocusInserted } from '@/components/directives'
import { CustomerService } from '@/services'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import _debounce from 'lodash.debounce'

export default {
  name: 'QuoteCustomer',
  inject: ['$vv'],
  directives: {
    FocusInserted
  },
  components: {
    CustomerAddressInput,
    BulmaAutocomplete
  },
  mixins: [],
  props: {
    value: null,
    quote: null
  },
  data: () => {
    return {
      innerCustomer: null,
      // defaultPhoneType: 1,
      defaultAddress: null,
      phones: [],
      phoneNoTokens: {
        P: {
          pattern: /[0-9, ()\-+]/
        }
      },
      phoneNoMask: Array(31).join('P'), // length of phoneNo field in database
      filteredCustomers: null,
      isSearchingCustomer: false,
      filter: {
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      maxEmails: 2,
      maxPhones: 3
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    emailTypes: function () {
      return EmailTypes
    },
    phoneTypes() {
      return PhoneTypes
    },
    activePhones: function () {
      if (typeof this.innerCustomer.phones !== 'undefined') {
        return this.innerCustomer.phones.filter((x) => !x.isDeleted).slice(0, this.maxPhones)
      }
      return []
    },
    activeEmails: function () {
      if (typeof this.innerCustomer.emails !== 'undefined') {
        return this.innerCustomer.emails.filter((x) => !x.isDeleted).slice(0, this.maxEmails)
      }
      return []
    }
  },
  watch: {
    innerCustomer: {
      handler: function (newVal, oldVal) {
        this.innerCustomer.fullname = this.innerCustomer.isPerson
          ? `${this.innerCustomer.lastname}, ${this.innerCustomer.firstname}`
          : this.innerCustomer.companyName
        this.$emit('input', newVal)
      },
      deep: true
    },
    defaultAddress: {
      handler: function (newVal, oldVal) {
        if (
          newVal.isNew &&
          this.innerCustomer.addresses.length === 0 &&
          (newVal.address1 || newVal.suburbText || newVal.suburbPostcode || newVal.countryCode || newVal.countryCode)
        ) {
          this.innerCustomer.addresses.splice(this.innerCustomer.addresses.length, 1, newVal)
        } else if (!newVal.isNew && this.innerCustomer.addresses.length > 0) {
          const defaultAddressIndex = this.innerCustomer.addresses.findIndex((a) => a.isDefault)
          this.innerCustomer.addresses.splice(defaultAddressIndex, 1, newVal)
        }
      },
      deep: true
    }
  },
  created() {
    this.innerCustomer = _cloneDeep(this.value)
    this.$vv.customer.$reset()
    this.getDefaultAddress()
    this.getCustomerDropdownProto()
  },
  mounted() {
    this.$eventHub.$on(`${EventHubTypes.EntitySaved}-${this.$parent.$options.name}`, this.updateInnerCustomer)
    // this.$vv.customer.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(`${EventHubTypes.EntitySaved}-${this.$parent.$options.name}`)
  },
  methods: {
    addEmail() {
      if (this.activeEmails.length < 2) {
        const newEmail = {
          id: null,
          type: 0,
          isDefault: this.activeEmails.filter((x) => x.isDefault).length === 0,
          isNew: true,
          isDeleted: false,
          address: ''
        }
        this.innerCustomer.emails.splice(this.innerCustomer.emails.length, 1, newEmail)
      }
    },
    deleteEmail: function (email, index) {
      if (email.isNew) {
        this.innerCustomer.emails.splice(index, 1)
      } else {
        email.isDeleted = true
        email.address = 'deleted@deleted.com'
      }
      if (email.isDefault && this.activeEmails.length > 0) {
        this.activeEmails[0].isDefault = true
      }
    },
    setDefaultEmail(index) {
      this.innerCustomer.emails.forEach((p, i) => {
        p.isDefault = index === i
      })
    },
    addPhone() {
      if (this.activePhones.length < 3) {
        const newPhone = {
          id: null,
          type: this.activePhones.length == 0 ? PhoneTypes.Mobile : PhoneTypes.Home,
          no: '',
          isDefault: this.activePhones.filter((x) => x.isDefault).length === 0,
          isNew: true,
          isDeleted: false
        }
        this.innerCustomer.phones.splice(this.innerCustomer.phones.length, 1, newPhone)
      }
    },
    deletePhone: function (phone, index) {
      if (phone.isNew) {
        this.innerCustomer.phones.splice(index, 1)
      } else {
        phone.isDeleted = true
        phone.no = 'deleted'
      }

      if (phone.isDefault && this.activePhones.length > 0) {
        this.activePhones[0].isDefault = true
        this.activePhones[0].isDirty = true
      }
    },
    setDefaultPhone(index) {
      this.innerCustomer.phones.forEach((p, i) => {
        p.isDefault = index === i
      })
    },
    getDefaultAddress() {
      this.defaultAddress = {
        id: null,
        type: 0,
        addressType: 1,
        address1: '',
        address2: '',
        address3: '',
        suburbText: '',
        suburbPostcode: '',
        stateCode: '',
        stateText: '',
        countryCode: '',
        countryText: '',
        assetID: this.innerCustomer.id,
        isDefault: true,
        isDeleted: false,
        isNew: true
      }
      if (this.innerCustomer.addresses.length > 0 && this.innerCustomer.addresses.some((a) => a.isDefault)) {
        this.defaultAddress = this.innerCustomer.addresses.find((a) => a.isDefault)
      }
    },
    async updateInnerCustomer() {
      console.log(`${this.$options.name} - ${this.value.modifiedDate}`)
      console.log(`${this.$options.name} - ${this.quote.modifiedDate}`)
      await this.$nextTick()
      this.innerCustomer = _cloneDeep(this.value)
      this.innerQuote = _cloneDeep(this.quote)
      this.getDefaultAddress()
      this.$refs.addressInput.refreshAddress()
    },
    async getCustomerDropdownProto() {
      this.isSearchingCustomer = true
      this.filteredCustomers = await CustomerService.getCustomerDropdownProto(this.filter)
      this.isSearchingCustomer = false
    },
    searchCustomer: _debounce(async function (type) {
      if (type === 'firstname') {
        this.filter.name = this.innerCustomer.firstname
      } else if (type === 'lastname') {
        this.filter.name = this.innerCustomer.lastname
      } else if (type === 'companyName') {
        this.filter.name = this.innerCustomer.companyName
      }
      this.getCustomerDropdownProto()
    }, 300),
    async selectCustomer(selected) {
      if (selected) {
        this.$emit('select-customer', selected.key)
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 0 0 1.2rem 0;
}
div.tile.is-parent {
  min-height: calc(100vh - 8rem);
}
</style>

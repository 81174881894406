<template>
  <div class="field">
    <div class="field">
      <label class="label">Address</label>
      <div class="field has-addons">
        <div class="control">
          <span class="select custom-select">
            <select v-model="address.type">
              <option v-for="(a, addressType) in addressTypes"
                :value="addressType"
                :key="a"
                :title="a">
                {{ Array.from(a)[0] }}
              </option>
            </select>
          </span>
        </div>
        <div class="control is-expanded">
          <bulma-autocomplete :id="id + '_address'"
            placeholder="Start typing address"
            v-model="address.address1"
            :data="addresses.data"
            field="street"
            :loading="addresses.loading"
            @input="searchAddress(address, $event)"
            @select="pickAddress(address, $event)">
            <template slot-scope="props">
              <div>
                <span class="icon is-small">
                  <i class="mdi mdi-map-marker" />
                </span>
                {{ `${props.option.street}, ${props.option.secondary_text}` }}
              </div>
            </template>
          </bulma-autocomplete>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Suburb</label>
      <div class="control">
        <input v-model="address.suburbText"
          class="input"
          type="text"
          placeholder="Suburb">
      </div>
    </div>
    <div class="field">
      <label class="label">State</label>
      <div class="control">
        <input v-model="address.stateCode"
          class="input"
          type="text"
          placeholder="State">
      </div>
    </div>
    <div class="field">
      <div class="field-body">
        <div class="field">
          <div class="control">
            <label class="label">Postal code</label>
            <input v-model="address.suburbPostcode"
              class="input"
              type="text"
              placeholder="Postal code">
          </div>
        </div>
        <div class="field">
          <div class="control">
            <label class="label">Country</label>
            <input v-model="address.countryText"
              class="input"
              type="text"
              placeholder="Country">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce'
import _cloneDeep from 'lodash.clonedeep'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import Neighbours from '@/assets/json/neighbours.json'
import { AddressTypes } from '@/enums'

export default {
  name: 'CustomerAddressInput',
  components: {
    BulmaAutocomplete
  },
  mixins: [],
  props: {
    value: null,
    id: {
      type: String,
      default: ''
    },
    v: null,
    bounds: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {
      address: null,
      googleApi: {
        mapUrl: 'https://maps.googleapis.com/maps/api/js',
        mapKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        mapLibraries: 'places',
        mapCallback: 'initMap',
        mapStatus: null,
        bounds: null
      },
      addresses: {
        data: [],
        loading: false
      },
      suburbs: {
        data: [],
        loading: false
      },
      isPauseWatch: false
    }
  },
  computed: {
    countryCodes() {
      let neighbours = Neighbours.find((n) => n.countryCode === this.$company.info.countryCode) || []
      return [this.$company.info.countryCode, ...neighbours.neighbours]
    },
    addressTypes() {
      return AddressTypes
    }
  },
  watch: {
    address: {
      handler: function (newVal, oldVal) {
        if (!this.isPauseWatch) {
          this.$emit('input', newVal)
        }
      },
      deep: true
    }
  },
  created() {
    this.address = _cloneDeep(this.value)
    const vm = this
    window.initMap = function () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          const circle = new window.google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          })

          vm.googleApi.bounds = circle.getBounds()
        })
      }

      vm.googleApi.mapStatus = 'AddressDetail'
    }

    if (!window.google) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `${this.googleApi.mapUrl}?key=${this.googleApi.mapKey}&libraries=${this.googleApi.mapLibraries}&callback=${this.googleApi.mapCallback}`
      document.body.appendChild(script)
    } else {
      window.initMap()
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    searchAddress: _debounce(function (address, key) {
      this.addresses.loading = true
      if (key.length > 0) {
        const searchOptions = {
          input: key,
          types: ['geocode'],
          componentRestrictions: {
            country: this.countryCodes
          }
        }
        if (this.bounds) {
          searchOptions.bounds = this.googleApi.bounds
        }
        var service = new window.google.maps.places.AutocompleteService()
        service.getPlacePredictions(searchOptions, (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            // alert(status)
            return
          }
          const addresses = []
          predictions.forEach(function (prediction) {
            // console.log(prediction)
            addresses.push({
              id: prediction.place_id,
              street: `${prediction.structured_formatting.main_text}`,
              secondary_text: prediction.structured_formatting.secondary_text
            })
          })
          this.addresses.data = addresses
        })
      }
      this.addresses.loading = false
    }, 500),
    pickAddress: function (address, e) {
      if (!e) {
        return
      }
      var service = new window.google.maps.Geocoder()
      service.geocode(
        {
          placeId: e.id
        },
        function (results, status) {
          if (status === 'OK') {
            const place = results[0]
            if (place.address_components !== undefined) {
              // reset address1, otherwise street only search will double up
              address.address1 = ''
              for (let i = 0; i < place.address_components.length; i++) {
                const addressType = place.address_components[i].types[0]
                switch (addressType) {
                  case 'street_number':
                    address.address1 = place.address_components[i]['short_name']
                    break
                  case 'route':
                    address.address1 += ' ' + place.address_components[i]['long_name']
                    break
                  case 'locality':
                    address.suburbText = place.address_components[i]['long_name']
                    break
                  case 'postal_code':
                    address.suburbPostcode = place.address_components[i]['short_name']
                    break
                  case 'administrative_area_level_1':
                    address.stateText = place.address_components[i]['long_name']
                    address.stateCode = place.address_components[i]['short_name']
                    break
                  case 'country':
                    address.countryText = place.address_components[i]['long_name']
                    address.countryCode = place.address_components[i]['short_name']
                    break
                }
              }
            }
          }
        }
      )
    },
    refreshAddress() {
      this.isPauseWatch = true
      this.address = _cloneDeep(this.value)
      this.isPauseWatch = false
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>
